
  .modal > .header {
    text-align: center;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }
  [role='tooltip'].popup-content {
    width: 300px;
    padding:10px;
    border-radius: 10px;
    border:1px solid #fd510049;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    background: rgba(27, 37, 75, 0.97);
  }